// composables/useSocialStore.ts


// Define the structure for your socials 
interface Social {
    icon: string;
    name: string;
    url: string;
}

// State
const socialItems = ref<Social[]>([]);

// Actions
// global
function setSocialItems(items: Social[]) {
    socialItems.value = items;
}

async function fetchSocialData() {
    try {
        const { getItems } = useDirectusItems();
        const data = await getItems<Social>({ 
            collection: "contacts",
            fields: ['icon', 'name', 'url'],
        });
        setSocialItems(data);
    } catch (e) {
        console.error("Failed to fetch data:", e);
        setSocialItems([]); // Set to empty array to indicate failure
    }
}

// Getters
const icons = computed(() => socialItems.value.map(item => item.icon) || ['loading']);
const names = computed(() => socialItems.value.map(item => item.name) || ['loading']);
const urls = computed(() => socialItems.value.map(item => item.url) || ['loading']);

export function useSocialStore() {
    return {
        socialItems,
        fetchSocialData,
        icons,
        names,
        urls,
    };
}